import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom"

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter basename='/'>
      <Routes>
        <Route path="/" element={<h1>root</h1>}></Route>
        <Route path="test" element={<h1>test</h1>}></Route>
      </Routes>
    </BrowserRouter>    
  </React.StrictMode>
);